<template>
  <div class="video_box" v-if="url">
    <div class="container">
      <div class="player">
        <video-player
          class="video-player vjs-custom-skin video-swiper"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
        ></video-player>
        <!-- <video :src="url" controls="controls"></video> -->
      </div>
    </div>
  </div>
</template>
 
<script>
import { videoPlayer } from "vue-video-player";
import { mapMutations } from "vuex";
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    time: {
      type: Number,
    },
    cover: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      videoUrl: "",
      playerOptions: {
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: this.url,
          },
        ],
        poster: this.cover, //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      state: "",
      paused: "",
      running: "",
    };
  },
  created() {
    if (this.url) {
      if (this.cover) {
        this.videoUrl = this.cover;
      } else if (this.time) {
        this.videoUrl = `${this.url}?x-oss-process=video/snapshot,t_${this.time},f_jpg,w_590,h_332,m_fast`;
      }
      this.playerOptions = {
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: this.url,
          },
        ],
        poster: this.videoUrl, //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      };
      const that = this;
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  components: {
    videoPlayer,
  },
  methods: {
    // ...mapMutations({
    //   setVideoOpen: "setVideoOpen"
    // }),
    onPlayerPlay(player) {
      this.$emit("onPlayerPlay", this.index);
    },
    onPlayerPause(player) {
      // this.$emit('onPlayerPause',this.ind);
      // console.log("暂停视频");
    },
    pause() {
      this.$refs.videoPlayer.player.pause();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.container {
  background-color: #f5f5f5;
  min-height: 100%;
}
.video_box,
.container,
.player,
.video-player {
  height: 100%;
  // touch-action: none;
}
// .player{
//   ::v-deep .vjs-control-bar{
//     .video-js .vjs-control{
//       flex:initial
//     }
//   }
// }
.player {
  video {
    width: 100%;
  }
}
.video-player {
  ::v-deep {
    .vjs-fullscreen {
      .vjs-control-bar {
        .vjs-time-control {
          display: flex;
        }
        .vjs-control {
          display: flex;
          &.vjs-volume-panel-horizontal {
            .vjs-volume-control {
              &.vjs-volume-horizontal {
                width: 5em;
              }
            }
          }
        }
        // font-size: 0.24rem;
      }
    }
    .video-js .vjs-big-play-button {
      transform: scale(0.5);
      width: 72px;
      height: 72px;
      border-radius: 100%;
      z-index: 100;
      color: #fff;
      border: solid 1px #979797;
      margin-left: -36px;
    }
    .vjs-control-bar {
      .vjs-time-control {
        display: none;
      }
      .vjs-control {
        display: none;
        flex: initial;
        &.vjs-fullscreen-control {
          display: flex;
          margin-left: auto;
        }
        &.vjs-progress-control {
          flex: auto;
        }
        &.vjs-volume-panel-horizontal {
          .vjs-mute-control {
            padding: 0 1.5em 3em;
          }
          .vjs-volume-control {
            width: 3em;
          }
        }
      }
    }
  }
}
// ::v-deep
</style> 