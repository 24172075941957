<template>
  <div class="m-card s-2" v-if="visitList && visitList.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">
          Visitor
          <!-- <template v-if="visitCount">（{{ visitCount }}）</template> -->
          <template v-if="exhibitionInfo && exhibitionInfo.userCount">（共{{ exhibitionInfo.userCount }}人）</template>
        </span>
      </div>
    </div>
    <div class="m-imglist" v-if="visitList && visitList.length">
      <div :key="'m-imglist1-v-' + index" class="m-imglist-item s-6" v-for="(item, index) in visitList">
        <div class="imgbox">
          <img :src="item.userInfo.avatarUrl" v-if="item.userInfo && item.userInfo.avatarUrl" />
          <img src="@/assets/img/user.png" v-else />
        </div>
      </div>
    </div>
    <div class="u-empty" v-else>{{ $t("lang.No_data") }}</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "visit-module",
  data() {
    return {
      visitList: [], // 访客列表
      visitCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
    }),
    visitOption() {
      let query = `
        query($query:_Any!){
          exhibitionUserQuery {
            page(
              query: $query
            ) {
              totalCount
              items {
                userInfo
              }
            }
          }
        }
      `;
      let where = {
        equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        // notEqual_userInfo: { n: "userInfo" },
        // notEqual_userInfo2: { n: "userInfo", v: "" }  
      };
      let opt = {
        query: query,
        variables: {
          query: {
            where: where,
            page: 1,
            size: 12,
            order: [{ n: "updateAt", v: 0 }],
          },
        },
      };
      return opt;
    },
    exhibitionId() {
      return this.$route.params.id || "";
    },
  },
  created() {
    this.visitInit(); // 访客初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      getShareConfig: "share/getShareConfig",
    }),
    async visitInit() {
      let data = await this.graphqlPost(this.visitOption);
      let res = JSON.parse(JSON.stringify(data.data.exhibitionUserQuery));
      this.visitCount = res.page.totalCount;
      if (res.page && res.page.items.length) {
        let arr = [];
        res.page.items.map((c) => {
          if (c.userInfo) {
            c.userInfo = JSON.parse(c.userInfo);
            arr.push(c);
          } else {
            arr.push({});
          }
        });
        this.visitList = arr;
      }
      console.log("visitList", this.visitList);
    },
  },
};
</script>

<style lang="scss" scoped></style>
