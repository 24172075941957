<template>
  <div class="demand-center">
    <div class="category">
      <div :class="cageId == index ? 'is-active' : ''" :key="index" class="category-item" v-for="(item, index) in categoryList" @click="changeCategory(index)">
        {{ item }}
      </div>
    </div>
    <div style="margin-bottom: 12px;" v-if="cageId === 0">
      <Input :placeholder="$t('lang.Please_add_product_name')" v-model="productName" />
    </div>
    <div style="margin-bottom: 12px;" v-else>
       <Select v-model="productName" style="width:270px" :placeholder="$t('lang.Please_add_product_name')">
        <Option v-for="item in proList" :value="item.name" :key="item.name">{{ item.name }}</Option>
      </Select>
    </div>
    <div class="textarea">
      <Input :placeholder="$t('lang.Please_add_a_brief_description')" :rows="4" type="textarea" v-model="content" />
    </div>
    <div class="upload-item">
      <div class="my-upload">
        <div :key="'upload' + index" class="my-upload-list" v-for="(item, index) in uploadList">
          <template v-if="item.status === 'finished'">
            <img :src="item.url" style="width: 46px; height: 46px" />
            <div class="my-upload-list-cover">
              <Icon @click.native="handleView(item.url)" type="ios-eye-outline"></Icon>
              <Icon @click.native.stop.prevent="handleRemove(item)" type="ios-trash-outline"></Icon>
            </div>
          </template>
          <template v-else>
            <Spin style="width: 46px;height: 46px;position: absolute;top: 0;">
              <Icon class="demo-spin-icon-load" size="20" type="ios-loading"></Icon>
            </Spin>
          </template>
        </div>
        <Upload
          :action="uploadUrl"
          :before-upload="handleBeforeUpload"
          :default-file-list="defaultList"
          :format="['jpg', 'jpeg', 'png']"
          :headers="formHeaders"
          :max-size="2048"
          :on-exceeded-size="handleMaxSize"
          :on-format-error="handleFormatError"
          :on-success="handleSuccess"
          :show-upload-list="false"
          ref="upload"
          style="display: inline-block; position: relative"
          type="drag"
          v-show="uploadList && uploadList.length == 0"
        >
          <div style="width: 46px; height: 46px; color: rgb(133, 133, 133)">
            <div style="font-size: 16px">
              +
              <p style="font-size: 12px">{{ $t("lang.Upload") }}</p>
            </div>
          </div>
        </Upload>

        <!-- <div :key="'upload2' + index2" class="my-upload-list" style="position: relative" v-for="(item2, index2) in uploadList2">
          <template v-if="item2.status === 'finished'">
            <img :src="item2.url" style="width: 46px; height: 46px" />
            <div class="my-upload-list-cover">
              <Icon @click.native="handleView(item2.url)" type="ios-eye-outline"></Icon>
              <Icon @click.native.stop.prevent="handleRemove2(item2)" type="ios-trash-outline"></Icon>
            </div>
          </template>
          <template v-else>
            <Spin style="width: 46px;height: 46px;position: absolute;top: 0;">
              <Icon class="demo-spin-icon-load" size="20" type="ios-loading"></Icon>
            </Spin>
          </template>
        </div>
        <Upload
          :action="uploadUrl"
          :before-upload="handleBeforeUpload2"
          :default-file-list="defaultList2"
          :format="['jpg', 'jpeg', 'png']"
          :headers="formHeaders"
          :max-size="2048"
          :on-exceeded-size="handleMaxSize"
          :on-format-error="handleFormatError2"
          :on-success="handleSuccess2"
          :show-upload-list="false"
          ref="upload2"
          style="display: inline-block"
          type="drag"
          v-show="uploadList2 && uploadList2.length < 1"
        >
          <div style="width: 46px;height: 46px; color: rgb(133, 133, 133);">
            <div style="font-size: 16px">
              +
              <p style="font-size: 12px">{{ $t("lang.Upload") }}</p>
            </div>
          </div>
        </Upload>

        <div :key="'upload3' + index3" class="my-upload-list" style="position: relative" v-for="(item3, index3) in uploadList3">
          <template v-if="item3.status === 'finished'">
            <img :src="item3.url" style="width: 46px; height: 46px" />
            <div class="my-upload-list-cover">
              <Icon @click.native="handleView(item3.url)" type="ios-eye-outline"></Icon>
              <Icon @click.native.stop.prevent="handleRemove3(item3)" type="ios-trash-outline"></Icon>
            </div>
          </template>
          <template v-else>
            <Spin style="width: 46px;height: 46px;position: absolute;top: 0;">
              <Icon class="demo-spin-icon-load" size="20" type="ios-loading"></Icon>
            </Spin>
          </template>
        </div>
        <Upload
          :action="uploadUrl"
          :before-upload="handleBeforeUpload3"
          :default-file-list="defaultList3"
          :format="['jpg', 'jpeg', 'png']"
          :headers="formHeaders"
          :max-size="2048"
          :on-exceeded-size="handleMaxSize"
          :on-format-error="handleFormatError3"
          :on-success="handleSuccess3"
          :show-upload-list="false"
          ref="upload3"
          style="display: inline-block"
          type="drag"
          v-show="uploadList3 && uploadList3.length < 1"
        >
          <div style="width: 46px;height: 46px;color: rgb(133, 133, 133);">
            <div style="font-size: 16px">
              +
              <p style="font-size: 12px">{{ $t("lang.Upload") }}</p>
            </div>
          </div>
        </Upload>

        <div :key="'upload4' + index4" class="my-upload-list" style="position: relative" v-for="(item4, index4) in uploadList4">
          <template v-if="item4.status === 'finished'">
            <img :src="item4.url" style="width: 46px; height: 46px" />
            <div class="my-upload-list-cover">
              <Icon @click.native="handleView(item4.url)" type="ios-eye-outline"></Icon>
              <Icon @click.native.stop.prevent="handleRemove4(item4)" type="ios-trash-outline"></Icon>
            </div>
          </template>
          <template v-else>
            <Spin style="width: 46px;height: 46px;position: absolute;top: 0;">
              <Icon class="demo-spin-icon-load" size="20" type="ios-loading"></Icon>
            </Spin>
          </template>
        </div>
        <Upload
          :action="uploadUrl"
          :before-upload="handleBeforeUpload4"
          :default-file-list="defaultList4"
          :format="['jpg', 'jpeg', 'png']"
          :headers="formHeaders"
          :max-size="2048"
          :on-exceeded-size="handleMaxSize"
          :on-format-error="handleFormatError4"
          :on-success="handleSuccess4"
          :show-upload-list="false"
          ref="upload4"
          style="display: inline-block"
          type="drag"
          v-show="uploadList4 && uploadList4.length < 1"
        >
          <div style="width: 46px;height: 46px;color: rgb(133, 133, 133);">
            <div style="font-size: 16px">
              +
              <p style="font-size: 12px">{{ $t("lang.Upload") }}</p>
            </div>
          </div>
        </Upload>

        <div :key="'upload5' + index5" class="my-upload-list" style="position: relative" v-for="(item5, index5) in uploadList5">
          <template v-if="item5.status === 'finished'">
            <img :src="item5.url" style="width: 46px; height: 46px" />
            <div class="my-upload-list-cover">
              <Icon @click.native="handleView(item5.url)" type="ios-eye-outline"></Icon>
              <Icon @click.native.stop.prevent="handleRemove5(item5)" type="ios-trash-outline"></Icon>
            </div>
          </template>
          <template v-else>
            <Spin style="width: 46px;height: 46px;position: absolute;top: 0;">
              <Icon class="demo-spin-icon-load" size="20" type="ios-loading"></Icon>
            </Spin>
          </template>
        </div>
        <Upload
          :action="uploadUrl"
          :before-upload="handleBeforeUpload5"
          :default-file-list="defaultList5"
          :format="['jpg', 'jpeg', 'png']"
          :headers="formHeaders"
          :max-size="2048"
          :on-exceeded-size="handleMaxSize"
          :on-format-error="handleFormatError5"
          :on-success="handleSuccess5"
          :show-upload-list="false"
          ref="upload5"
          style="display: inline-block"
          type="drag"
          v-show="uploadList5 && uploadList5.length < 1"
        >
          <div style="width: 46px;height: 46px;color: rgb(133, 133, 133);">
            <div style="font-size: 16px">
              +
              <p style="font-size: 12px">{{ $t("lang.Upload") }}</p>
            </div>
          </div>
        </Upload> -->

        <iModal cancel-text title="View" v-model="visible">
          <div style="text-align: center">
            <img :src="imgName" style="max-width: 100%" v-if="visible" />
          </div>
        </iModal>
      </div>
    </div>
    <div @click="supplydemandHandle" class="submit">{{ $t("lang.Submit") }}</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  name: "demand",
  data() {
    return {
      length: 0,
      imgList: [],
      SuppluImgList: [], //供需上传图片数组
      guanzhu: false,
      cageId: 0, //供需类别索引
      content: "", //供需内容
      productName: "", // 采购的采购产品
      uploadList: [],
      // uploadList2: [],
      // uploadList3: [],
      // uploadList4: [],
      // uploadList5: [],
      defaultList: [],
      // defaultList2: [],
      // defaultList3: [],
      // defaultList4: [],
      // defaultList5: [],
      visible: false,
      imgName: "",
      value6: "",
      supplyId: "",
      show: false,
      page: 1,
      size: 12,
      totalCount: 0, //供需总条数
      demandList: [], //供需列表
      categoryindex: 0, //选中的类别
      SupplymemberId: "",
      query: "",
      proList: [],
    };
  },
  computed: {
    ...mapGetters({
      searchType: "getSearchType",
      searchTxt: "getSearchTxt",
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
      formHeaders: "getFormHeaders",
      uploadUrl: "getUploadUrl",
      exhibitionInfo: "getExhibitionInfo",
      token: "getToken",
      login: "getLogin",
    }),
    isRegOk() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole && this.userInfo.inMember.userRole != "VISITOR" && this.userInfo.inMember.userRole != "Visitor";
    },
    categoryList() {
      return ["Purchase", "Supply"];
    },
    userRole() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole ? this.userInfo.inMember.userRole : "";
    },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      supplyDemand: "news/supplyDemand",
      supplyFollower: "news/supplyFollower",
      deleteSupplyFollower: "news/deleteSupplyFollower",
      unverifiedDialog: "unverifiedDialog",
    }),
    async init(){
      if(this.userRole == "EXHIBITOR"){
             let query = `
        query{
          exhibitorQuery{
            get(id:"${this.userInfo.inMember.exhibitorId}"){
                products(num:10000){
                  name
                  id
                  nameEn
                  langVersion 
                }
              }
            }
        }
      `;
      let opt = {
        query: query,
        variables: {},
      };
      let res = await this.graphqlPost(opt);
      console.log(res, "exinfo res");
      this.proList=res.data.exhibitorQuery.get.products
      }
    },
    //切换供需类别
    categoryTabs(index) {
      this.categoryindex = index;
      console.log(this.categoryList[this.categoryindex]);
      this.init();
    },
    changeCategory(index) {
      if (this.categoryList[index] === "Supply" && this.userRole !== "EXHIBITOR") {
        // this.$Message.warning("Only exhibitors can release supply information!");
        this.$Modal.warning({
            title: 'Only exhibitors can release supply information!',
        });
        return;
      }
      this.cageId = index;
    },
    //发布供需
    async supplydemandHandle(item) {
      if (!this.login || !this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if(this.userInfo&&this.userInfo.inMember.isBlacklist){
        this.$Message.warning('You do not have access');
        return 
      } 
      if (!this.content) {
        // this.$Message.warning('Please enter the Content');
        this.$Modal.warning({
            title: 'Please enter the Content',
        });
        return;
      }
      if (!this.productName) {
        // this.$Message.warning(this.$t("lang.Please_add_product_name"));
        this.$Modal.warning({
            title: this.$t("lang.Please_add_product_name"),
        });
        return;
      }
      if (this.cageId == -1) {
        // this.$Message.warning(this.$t("lang.Please_select_a_type"));
        this.$Modal.warning({
            title: this.$t("lang.Please_select_a_type"),
        });
        return;
      }
      let opt = {
        memberId: this.userInfo.inMember.memberId,
        type: this.categoryList[this.cageId],
        publisherType: 0,
        exhibitionId: this.exhibitionId,
        content: this.content,
        productName: this.productName,
        langVersion: "en",
      };
      if (this.SuppluImgList.length) {
        opt.imgUrls = this.SuppluImgList.join(";");
      }
      let data = await this.supplyDemand(opt);
      if (data.result) {
        this.$Message.success(this.$t("lang.Successfully_submitted"));
        this.content = "";
        this.productName = "";
        this.uploadList = [];
        // this.uploadList2 = [];
        // this.uploadList3 = [];
        // this.uploadList4 = [];
        // this.uploadList5 = [];
        this.cageId = -1;
      } else {
        this.$Message.warning(this.$t("lang.failed_to_submit"));
      }
    },
    //供需关注
    async supplyfollower(item) {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      let userInfo = {
        name: this.userInfo.name,
        avatarUrl: this.userInfo.avatarUrl,
        nickName: this.userInfo.nickName,
      };
      let opt = {
        supplyDemandId: item.id,
        memberId: this.userInfo.inMember.memberId,
        userInfo: JSON.stringify(userInfo),
      };
      let data = await this.supplyFollower(opt);
      console.log(data);
      if (data.result) {
        this.init();
      } else {
        this.$Message.warning("Fail");
      }
    },
    //取消关注
    async deSupplyFollower(item) {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      let opt = {
        supplyDemandId: item.id,
        memberId: this.userInfo.inMember.memberId,
      };
      let data = await this.deleteSupplyFollower(opt);
      console.log(data);
      if (data.result) {
        this.init();
      } else {
        this.$Message.warning("Fail");
      }
    },
    changePage(e) {
      this.page = e;
      this.init();
    },
    changePageSize(e) {
      this.size = e;
    },
    // 查看上传
    handleView(name) {
      this.imgName = name;
      this.visible = true;
    },
    // // 移除上传
    // handleRemove2(file) {
    //   const fileList = this.uploadList2;
    //   this.uploadList2.splice(fileList.indexOf(file), 1);
    //   this.SuppluImgList.splice(fileList.indexOf(file), 1);
    // },

    // // 上传成功
    // handleSuccess2(res, file) {
    //   if (res && res.data.length) {
    //     file.url = res.data[0];
    //     this.SuppluImgList.push(file.url);
    //     this.uploadList2 = this.$refs.upload2.fileList;
    //   }
    // },
    // // 文件格式错误
    // handleFormatError2(file) {
    //   this.$Message.warning(`The file format is incorrect, please upload JPG or PNG image`);
    // },
    // // 上传数量
    // handleBeforeUpload2() {
    //   const limitNum = 100;
    //   this.uploadList2.push({ status: "start" });
    //   const check = this.uploadList2.length < limitNum;
    //   if (!check) {
    //     this.$Message.warning(`Not more than ${limitNum}`);
    //   }
    //   return check;
    // },
    // 移除上传
    handleRemove(file) {
      const fileList = this.uploadList;
      this.uploadList.splice(fileList.indexOf(file), 1);
      this.SuppluImgList.splice(fileList.indexOf(file), 1);
      console.log(this.uploadList, "ohuseiufhwuihf");
    },

    // 上传成功
    handleSuccess(res, file) {
      if (res && res.data.length) {
        file.url = res.data[0];
        this.uploadList.status = "finished";
        this.SuppluImgList.push(file.url);
        this.uploadList = this.$refs.upload.fileList;
        console.log(this.uploadList, "upload");
      }
    },
    // 文件格式错误
    handleFormatError(file) {
      this.$Message.warning(`The file format is incorrect, please upload JPG or PNG image`);
    },
    // 上传数量
    handleBeforeUpload() {
      this.uploadList.push({ status: "start" });
      const limitNum = 100;
      const check = this.uploadList.length < limitNum;
      if (!check) {
        this.$Message.warning(`Not more than ${limitNum}`);
      }
      return check;
    },

    // // 移除上传
    // handleRemove3(file) {
    //   const fileList = this.uploadList3;
    //   this.uploadList3.splice(fileList.indexOf(file), 1);
    //   this.SuppluImgList.splice(fileList.indexOf(file), 1);
    // },

    // // 上传成功
    // handleSuccess3(res, file) {
    //   if (res && res.data.length) {
    //     file.url = res.data[0];
    //     this.SuppluImgList.push(file.url);
    //     this.uploadList3 = this.$refs.upload3.fileList;
    //   }
    // },
    // // 文件格式错误
    // handleFormatError3(file) {
    //   this.$Message.warning(`The file format is incorrect, please upload JPG or PNG image`);
    // },
    // // 上传数量
    // handleBeforeUpload3() {
    //   const limitNum = 100;
    //   this.uploadList3.push({ status: "start" });
    //   const check = this.uploadList3.length < limitNum;
    //   if (!check) {
    //     this.$Message.warning(`Not more than ${limitNum}`);
    //   }
    //   return check;
    // },

    // // 移除上传
    // handleRemove4(file) {
    //   const fileList = this.uploadList4;
    //   this.uploadList4.splice(fileList.indexOf(file), 1);
    //   this.SuppluImgList.splice(fileList.indexOf(file), 1);
    // },

    // // 上传成功
    // handleSuccess4(res, file) {
    //   if (res && res.data.length) {
    //     file.url = res.data[0];
    //     this.SuppluImgList.push(file.url);
    //     this.uploadList4 = this.$refs.upload4.fileList;
    //   }
    // },
    // // 文件格式错误
    // handleFormatError4(file) {
    //   this.$Message.warning(`The file format is incorrect, please upload JPG or PNG image`);
    // },
    // // 上传数量
    // handleBeforeUpload4() {
    //   const limitNum = 100;
    //   this.uploadList4.push({ status: "start" });
    //   const check = this.uploadList4.length < limitNum;
    //   if (!check) {
    //     this.$Message.warning(`Not more than ${limitNum}`);
    //   }
    //   return check;
    // },

    // // 移除上传
    // handleRemove5(file) {
    //   const fileList = this.uploadList5;
    //   this.uploadList5.splice(fileList.indexOf(file), 1);
    //   this.SuppluImgList.splice(fileList.indexOf(file), 1);
    // },

    // // 上传成功
    // handleSuccess5(res, file) {
    //   if (res && res.data.length) {
    //     file.url = res.data[0];
    //     this.SuppluImgList.push(file.url);
    //     this.uploadList5 = this.$refs.upload5.fileList;
    //   }
    // },
    // // 文件格式错误
    // handleFormatError5(file) {
    //   this.$Message.warning(`The file format is incorrect, please upload JPG or PNG image`);
    // },
    // // 上传数量
    // handleBeforeUpload5() {
    //   const limitNum = 100;
    //   this.uploadList5.push({ status: "start" });
    //   const check = this.uploadList5.length < limitNum;
    //   if (!check) {
    //     this.$Message.warning(`Not more than ${limitNum}`);
    //   }
    //   return check;
    // },
    // 文件过大
    handleMaxSize(file) {
      this.$Message.warning(`文件大小超过限制, 请上传小于2M的图片`);
    },
    imgClick(imgList) {
      this.modal4 = true;
      this.imgList = imgList;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

.my-upload {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.my-upload-list {
  display: inline-block;
  width: 46px;
  height: 46px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  line-height: 46px;
  background: #fff;
  position: relative;
  margin: 0;
}

.my-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.my-upload-list:hover .my-upload-list-cover {
  display: block;
}

.my-upload-list-cover i {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin: 0 2px;
}

.demand-center {
  padding: 20px;
  background-color: #fff;
  .category {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .category-item {
      flex: 1;
      height: 36px;
      background-color: #f7f7f7;
      border: solid 1px #dedede;
      border-radius: 4px;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 14px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }

      &.is-active {
        color: #fff;
        @include background_color(#1890ff);
        @include border_color(#1890ff);
      }
    }
  }

  .textarea {
    min-height: 94px;
    margin-bottom: 10px;
  }

  .upload-item {
    display: flex;
    justify-content: space-between;

    .uploadlist {
      width: 46px;
      height: 46px;
      background-color: #f7f7f7;
      border: dashed 1px #d9d9d9;
      cursor: pointer;

      span {
        width: 100%;
        display: flex;
        justify-content: center;
        color: #888;
      }
    }
  }

  .submit {
    width: 270px;
    height: 40px;
    @include background_color(#1890ff);
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
  }
}
</style>
