<template>
  <div class="m-card" v-if="activityList && activityList.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">Activities</span>
      </div>
      <div class="mc-top-itembox">
        <!-- <div
          :class="{ 's-primary': !activityHasLive }"
          @click="
            activityHasLive = false;
            activityInit();
          "
          class="mc-tip-item"
        >
          {{ $t("lang.Hot") }}
        </div> -->
        <div @click="$router.push(`/home/activity/${exhibitionId}`)" class="mc-tip-item">{{ $t("lang.More") }}</div>
      </div>
    </div>
    <div class="act_wrap" style="padding: 0 20px">
      <Row v-if="activityList && activityList.length">
        <Col :key="'m-list3-1' + index" span="12" v-for="(item, index) in activityList">
          <div @click="LinkAct(item)" class="m-list3 s-line" style="padding: 20px 20px 20px 0">
            <div class="ml3-left">
              <img :src="item.banners" v-if="item.banners" v-load="'oActSmall'" />
              <img src="@/assets/img/empty.png" v-else />
            </div>
            <div class="ml3-right">
              <div class="ml3-right-top">
                <span :title="item.title" class="ml3-txt">
                  {{ item.title }}
                </span>
                <span class="u-brand2" v-if="item.hasLive && item.liveAuditState === 'PASS' && item.liveStateIndex == 1">Live</span>
              </div>
              <div class="ml3-right-bottom">
                <p>
                  Date：{{ item.startTime | moment("YYYY/MM/DD HH:mm") }}
                  ~
                  {{ item.endTime | moment("HH:mm") }}
                </p>
                <p>Address：{{ item.address }}</p>
                <p v-if="item.company">{{ item.company.nameEn }}</p>
                <p v-else-if="item.isHostActivity">Official</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div class="u-empty" v-else>{{ $t("lang.No_data") }}</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "",
  data() {
    return {
      activityList: [], // 同期活动列表
      activityHasLive: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    activityOption() {
      const query = `
        query($query:QueryInput!){
          activityQuery{
            query(query:$query){
              hasNextPage
              pageIndex
              items{
                  acvitityType
                  banners
                  company{
                    name
                    nameEn
                  }
                  exhibitionId
                  exhibitorId
                  externalLink
                  followCount
                  isHostActivity
                  id
                  address
                  likeCount
                  shareCount
                  startTime
                  endTime
                  stateIndex
                  title
                  userCount
                  liveAuditState
                  hasLive
                  liveStateIndex
                  auditState
                  topic
                  topicIndex
                  viewCount
                  isShow
              }
            }
          }
        }
      `;
      let where = {
        equal_show: {
          n: "isShow",
          v: 1,
        },
        equal_IsShowExhibitor: {
          n: "IsShowExhibitor",
          v: 1,
        },
        equal_auditState: {
          n: "auditState",
          v: 1,
        },
        equal_exhibitionId: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        equal_isExhibitionActivity: {
          n: "isExhibitionActivity",
          v: 0,
        },
        notEqual_topic: {
          n: "topic",
          v: 0,
        },
      };
      if (this.activityHasLive) {
        where = {
          Equal: {
            n: "exhibitionId",
            v: this.exhibitionId,
          },
          Equal2: {
            n: "hasLive",
            v: true,
          },
          Equal3: {
            n: "liveAuditState",
            v: 1,
          },
          Equal4: {
            n: "isExhibitionActivity",
            v: 0,
          },
          Equal5: {
            n: "isShow",
            v: 1,
          },
        };
      }
      let order = [
        {
          n: "topic",
          v: 0,
        },
        {
          n: "topicIndex",
          v: 0,
        },
        {
          n: "auditTime",
          v: 0,
        },
      ];
      if (this.activityHasLive) {
        order = [
          { n: "LiveStateIndex", v: 1 },
          { n: "startTime", v: 1 },
          { n: "endTime", v: 0 },
          { n: "ViewCount", v: 0 },
          { n: "CreateAt", v: 0 },
        ];
      }
      let opt = {};
      return {
        query: query,
        variables: {
          query: {
            page: 1,
            size: 6,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
    },
  },
  created() {
    this.activityInit(); // 同期活动初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      LinkAct: "linkTo/LinkAct",
    }),
    async activityInit() {
      let res = await this.graphqlPost(this.activityOption);
      let data = JSON.parse(JSON.stringify(res.data.activityQuery.query));
      if (data) {
        data.items.map((e, i) => {
          if (e.banners && e.banners.indexOf("[") != -1) {
            let arr = JSON.parse(e.banners);
            e.banners = arr[0];
          }
        });
        console.log(data.items, "同期活动");
        this.activityList = data.items;
      }
      console.log("activityList", this.activityList);
    },
  },
};
</script>

<style lang="scss" scoped></style>
