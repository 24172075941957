<template>
  <div class="home-box">
    <gSwiperCommon :bannerType="'show.page.home.pc.en'" :bannerHeight="400"></gSwiperCommon>
    <div class="home-content">
      <div class="home">
        <liveModule></liveModule>
        <div class="g-layout">
          <div class="g-layout-l">
            <div class="m-card" v-if="enabledSupplyDemand">
              <div class="mc-top">
                <div class="mc-top-title">
                  <span class="mc-top-txt s-bold s-primary">{{ $t("lang.Create_a_Supply_Demand") }}</span>
                </div>
              </div>
              <publicDemand></publicDemand>
            </div>

            <cateModule></cateModule>

            <!-- <peopleModule></peopleModule> -->

            <otherExModule></otherExModule>

            <!-- <contact-module></contact-module> -->

            <visit-module></visit-module>
          </div>
          <div class="g-layout-r">
            <!-- 品牌展商 -->
            <brandExModule></brandExModule>
            <div class="g-layout2 s-nobottom" v-if="demandTotalCount || demandTotalCount2">
              <div class="g-layout2-l">
                <demandModule @setCount="setCount"></demandModule>
              </div>
              <div class="g-layout2-r">
                <demandModule2 @setCount="setCount2"></demandModule2>
              </div>
            </div>

            <!-- 热搜产品 -->
            <hotProduct></hotProduct>

            <!-- 同期活动 -->
            <time-activity></time-activity>

            <!-- 为您推荐 -->
            <topicProduct></topicProduct>

            <div class="g-layout2" v-if="totalCount || totalCount2">
              <div class="g-layout2-l">
                <more-video @setCount="setCount3"></more-video>
              </div>
              <div class="g-layout2-r">
                <!-- 为您推荐 -->
                <news-module @setCount="setCount4"></news-module>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import gSwiperCommon from "@components/gSwiperCommon/gSwiperCommon.vue";
import publicDemand from "@components/public-demand";
import liveModule from "./components/live-module.vue";
import peopleModule from "./components/people-module.vue";
import brandExModule from "./components/brand-ex-module.vue";
import demandModule from "./components/demand-module.vue";
import demandModule2 from "./components/demand-module2.vue";
import topicProduct from "./components/topic-product";
import hotProduct from "./components/hot-product";
import timeActivity from "./components/time-activity";
import moreVideo from "./components/more-video";
import newsModule from "./components/news-module";
import contactModule from "./components/contact-module";
import visitModule from "./components/visit-module";
import cateModule from "./components/cate-module";
import otherExModule from "./components/other-ex-module";

export default {
  name: "home",
  data() {
    return {
      demandTotalCount: 1,
      demandTotalCount2: 1,
      totalCount: 1,
      totalCount2: 1,
    };
  },
  components: {
    gSwiperCommon,
    publicDemand,
    liveModule,
    peopleModule,
    brandExModule,
    demandModule,
    demandModule2,
    demandModule2,
    topicProduct,
    hotProduct,
    timeActivity,
    moreVideo,
    newsModule,
    contactModule,
    visitModule,
    otherExModule,
    cateModule,
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      token: "getToken",
      login: "getLogin",
      exhibitionConfig: "getExhibitionConfig",
    }),
    enabledSupplyDemand() {
      return this.exhibitionConfig && this.exhibitionConfig.feature && this.exhibitionConfig.feature.enabledSupplyDemand;
    },
  },
  created() {
    document.title = this.exhibitionInfo ? this.exhibitionInfo.nameEn : "ExShow";
    this.setShare();
  },
  methods: {
    ...mapActions({
      getShareConfig: "share/getShareConfig",
      graphqlPost: "graphqlPost",
    }),
    ...mapMutations({
      setUser: "setUser",
      setUserId: "setUserId",
      setToken: "setToken",
    }),
    async setShare() {
      let data = await this.getShareConfig({ id: this.exhibitionId, name: "share.exhibition.config" });
      if (this.login) {
        this.getUser();
      }

      let share = {
        url: location.href,
        title: this.exhibitionInfo.nameEn,
        desc: this.exhibitionInfo.nameEn,
        summary: this.exhibitionInfo.remark,
        pics: this.exhibitionInfo.logo,
      };
      if (data) {
        let dataJson = JSON.parse(data);
        if (dataJson.content) {
          share.title = dataJson.content;
        }
        if (dataJson.des) {
          share.desc = dataJson.des;
          share.summary = dataJson.des;
        }
      }
      this.$store.commit("setShare", share);
    },
    linkTo(e) {
      if (e && e.url) {
        window.open(e.url);
      }
    },
    setCount(e) {
      this.demandTotalCount = e;
    },
    setCount2(e) {
      this.demandTotalCount2 = e;
    },
    setCount3(e) {
      this.totalCount = e;
    },
    setCount4(e) {
      this.totalCount2 = e;
    },
    async getUser() {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company 
              country
              county
              createAt
              deleteAt
              department
              email
              emailConfirmed
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                originLang
                isBlacklist
                originRemark
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                  company {
                    nameEn
                  }
                }
              }
              introduction
              isDeleted
              jobTitle
              jobTitleEn
              name
              nameEn
              nickName
              phone
              phoneText
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      if (data) {
        this.setUser(data.data.memberQuery.current);
        this.setUserId(data.data.memberQuery.current.userId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.home-content {
  position: relative;
  &::before {
    position: absolute;
    z-index: 1;
    content: "";
    top: -20px;
    left: 50%;
    margin-left: -50%;
    height: 200px;
    width: 100%;
    @include background_image(#8badd1);
    background-blend-mode: normal, normal;
  }
}
.home {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  .m-imglist {
    max-height: 122px;
    overflow: hidden;
  }
  .m-card {
    margin-bottom: 10px;
    .m-list3 .ml3-txt {
      max-width: 100%;
    }
    .u-brand2 {
      flex-shrink: 0;
    }
  }
  .g-layout .g-layout-l {
    width: 310px;
  }
  .g-layout .g-layout-r {
    margin-left: 10px;
    flex-shrink: 0;
    width: 880px;
  }
  .g-layout2 .g-layout2-r {
    min-height: 220px;
  }
  .m-list .ml-item-l {
    width: auto;
  }
}
</style>
