<template>
  <!-- 为您推荐 -->
  <div class="m-card" style="height: 374px;">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">{{ $t("lang.Moments") }}</span>
      </div>
      <div class="mc-top-itembox">
        <div class="mc-tip-item" @click="$router.push(`/home/about/${exhibitionId}`)">{{ $t("lang.More") }}</div>
      </div>
    </div>
    <div class="m-list2 video_wrap" v-if="videoList && videoList.length">
      <Row>
        <Col :key="'m-list2-1' + index" class="m-list2-box" span="12" v-for="(item, index) in videoList">
          <div class="ml2-item">
            <div class="ml2-item-box" style="padding-top: 56.4102564%" @contextmenu.prevent="rightClcik()">
              <video
                controlsList="nodownload"
                class="video_box"
                :src="item.url"
                :poster="item.cover"
                controls="controls"
                style="max-width: 100%"
              >Your browser does not support the "video" tag。</video>
              <!-- <videoPlayer :cover="item.cover" :index="index" :ref="'videoplay'" :url="item.url" @onPlayerPlay="onPlayerPlay"></videoPlayer> -->
            </div>
          </div>
          <div class="ml2-item-info" style="height: 50px;">
            <p>{{ item.titleEn }}</p>
          </div>
        </Col>
      </Row>
    </div>
    <div class="u-empty" v-if="dataLoading">{{ $t("lang.loading") }}</div>
    <div class="u-empty" v-if="!dataLoading && !videoList.length">{{ $t("lang.No_data") }}</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import videoPlayer from "../../../components/videoPlayer";
export default {
  name: "more-video",
  components: {
    videoPlayer,
  },
  data() {
    return {
      visitCount: 0,
      videoList: [], //精彩视频
      dataLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    videoOption() {
      let query = `
        query($query:QueryInput!){
          exhibitionVideoQuery{
            query(query:$query){
              hasNextPage
              indexFrom
              pageIndex
              pageSize
              totalCount
              items{
                cover
                coverIndex
                createAt
                title
                titleEn
                url
                id
                showIndex
              }
            }
          }
        }
      `;
      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      let order = [
        {
          N: "showIndex",
          v: 0,
        },
        {
          N: "updateAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: 1,
            size: 4,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      return opt;
    },
  },
  created() {
    this.videoInit(); //精彩视频初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    async videoInit() {
      this.dataLoading = true;
      let data = await this.graphqlPost(this.videoOption);
      let res = JSON.parse(JSON.stringify(data.data.exhibitionVideoQuery.query));
      this.videoList = res.items;
      this.$emit("setCount", res.totalCount);
      console.log(res, "视频初始化");
      this.dataLoading = false;
    },
    onPlayerPlay(index) {
      let arr = this.$refs.videoplay;
      for (let i = 0; i < arr.length; i++) {
        if (i != index) {
          arr[i].pause();
        }
      }
    },
    rightClcik(){
      // console.log('右键了')
    }
  },
};
</script>

<style lang="scss" scoped>
.video_wrap {
  padding: 20px 20px 0;
  ::v-deep .ivu-row {
    &::after,
    &::before {
      display: none;
    }
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .m-list2-box {
      float: initial;
      width: calc(50% - 5px);
      .ml2-item {
        padding: 0;
        .ml2-item-box {
          border: none;
          .video_box {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .ml2-item-info {
        padding: 10px 0;
      }
    }
  }
}
.ml2-item-info {
  p {
    text-align: left;
  }
}
</style>
