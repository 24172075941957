<template>
  <div class="m-card" v-if="exhibitionList && exhibitionList.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">{{ $t("lang.Concurrent_Exhibition") }}</span>
      </div>
    </div>
    <div class="list">
      <div class="content" v-if="exhibitionList && exhibitionList.length">
        <div class="list-item" v-for="(item, index) in exhibitionList" :key="'ca-' + index" @click="linkTo(item.id)">
          <div class="list-item-l">
            <img :src="item.logo" v-if="item.logo" v-load="'oPic'" />
            <img v-else src="@/assets/img/empty.png" />
          </div>
          <div class="list-item-r" :title="item.nameEn || item.shortNameEn">
            {{ item.nameEn || item.shortNameEn }}
          </div>
        </div>
      </div>
      <div v-else-if="!exhibitionList.length" class="u-empty">
        {{ $t("lang.No_data") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "other-ex-module",
  data() {
    return {
      exhibitionList: [],
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    exhibitionOption() {
      let query = `
        query($query: _Any!) {
          exhibitionQuery {
            page(query: $query) {
              items {
                id
                name
                nameEn
                shortName
                shortNameEn
                displayName
                showIndex
                addressEn
                logo
                remark
                remarkEn
                startData
                endDate
                banners { 
                  covers
                }
              }
            }
          }
        }    
      `;
      let opt = {
        query: query,
        variables: {
          query: {
            where: {
              equal_hostId: {
                n: "hostId",
                v: this.exhibitionInfo.hostId,
              },
            },
            size: 5,
            order: [
              {
                n: "showIndex",
                v: 0,
              },
            ],
          },
        },
      };
      return opt;
    },
  },
  created() {
    this.exhibitionInit();
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    async exhibitionInit() {
      let res = await this.graphqlPost(this.exhibitionOption);
      if (res.data && res.data.exhibitionQuery && res.data.exhibitionQuery.page) {
        let arr = res.data.exhibitionQuery.page;
        arr.items.map((c) => {
          if (c.banners[0]) {
            c.covers = c.banners[0].covers;
          }
          if (!c.covers) {
            c.covers = c.logo;
          }
        });
        this.exhibitionList = arr.items;
      }
    },
    linkTo(id) {
      window.open(location.origin + "/home/index/" + id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.list {
  padding: 20px 0 0 0;
  &-item {
    padding: 0 37px 20px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &-l {
      width: 56px;
      height: 56px;
      background-color: #fff;
      border: solid 1px #eee;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-r {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      font-size: 14px;
      color: #333;
      &:hover {
        @include font_color(#1890ff);
      }
    }
  }
}
</style>
