<template>
  <div class="m-card" v-if="demandList && demandList.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">行业人脉</span>
      </div>
      <div class="mc-top-itembox">
        <div @click="$router.push(`/home/demand/${exhibitionId}`)" class="mc-tip-item">{{ $t("lang.More") }}</div>
      </div>
    </div>
    <div class="m-info">
      <div :key="'m-info1' + index" class="mi-item" v-for="(item, index) in [2, 2, 2, 2]">
        <div class="mi-item-img">
          <img src="https://dss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2057588226,2402156864&fm=111&gp=0.jpg" />
        </div>
        <div class="mi-item-content">
          <div class="mi-item-txt">
            <span class="mii-txt1">异能靖</span>
            <span class="mii-txt2">总经理</span>
          </div>
          <div class="u-brand s-orange">专业线</div>
        </div>
      </div>
    </div>
    <div @click="linkToRegSteps" class="u-btn" style="padding-top: 0">
      <Button ghost long size="large" type="primary">立即注册</Button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "people-module",
  data() {
    return {
      demandList: [], // 供需列表
      demandPageIndex: 1,
      demandPageSize: 5,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    demandOption() {
      let query = `
        query($query:QueryInput!){
          supplyDemandQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items {
                  content
                  imgUrls
                  memberId 
                  publisherType
                  viewCount
                  updateAt
                  type
                  commentCount
                  id
                  auditTime
                  member{
                    id
                    avatarUrl
                    company
                    jobTitle
                    name
                  }
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal1: {
          n: "auditState",
          v: 1,
        },
        Equal2: {
          n: "type",
          v: "Supply products",
        },
        Equal_isdeleted: {
          n: "isDeleted",
          v: false,
        },
      };
      let order = [{ N: "updateAt", v: 0 }];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.demandPageIndex,
            size: this.demandPageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      return opt;
    },
  },
  created() {
    this.demandInit(); // 供需列表初始化
  },
  methods: {
    ...mapActions({
      linkToRegSteps: "linkTo/linkToRegSteps",
    }),
    async demandInit() {
      // 供需列表初始化
      let data = await this.graphqlPost(this.demandOption);
      this.demandTotalCount = data.data.supplyDemandQuery.query.totalCount;
      this.demandList = data.data.supplyDemandQuery.query.items;
      if (this.demandList) {
        this.demandList.forEach((c) => {
          if (c.imgUrls) {
            c.imgUrls = c.imgUrls.split(";");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
